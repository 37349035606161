import {
	extend,
	noop
} from '../../../shared/js/utils/index';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Data           from '../../../shared/js/dom/data';
import EventHandler   from '../../../shared/js/dom/event-handler';

// -------
// Private
// -------

const NAME      = 'slider';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const EVENT_INIT           = `init${EVENT_KEY}`;
const EVENT_ENTER_VIEWPORT = `enter-viewport${EVENT_KEY}`;
const EVENT_LEAVE_VIEWPORT = `leave-viewport${EVENT_KEY}`;

const DEFAULTS = {
	autoHeight         : false,
	init               : true,
	lazy               : {
		elementClass: 'slider-lazyload',
		loadPrevNext: true
	},
	loop               : true,
	pagination         : {
		clickable: true
	},
	slidesPerView      : 1,
	spaceBetween       : 38,
	speed              : 400,
	watchSlidesProgress: true
};

const SLIDER_VIEWPORT_OBSERVER = new IntersectionObserver(function(entries, observer) {
	for (const element of entries) {
		const target = element.target;
		const api    = Data.get(target, `${DATA_KEY}${API_KEY}`);

		if (element.isIntersecting) {
			if (api) {
				EventHandler.trigger(target, EVENT_ENTER_VIEWPORT);
			} else {
				EventHandler.trigger(target, EVENT_INIT);
			}
		} else {
			EventHandler.trigger(target, EVENT_LEAVE_VIEWPORT);
		}
	}
});

/**
 * Steuerelemente des Sliders holen und als Einstellungsobjekt zurückgeben.
 *
 * @param {HTMLElement} element
 * @returns {Object}
 */
const detectSliderControls = (element) => {
	const pagination = SelectorEngine.findOne('.swiper-pagination', element);
	const btnNext    = SelectorEngine.findOne('.swiper-button-next', element);
	const btnPrev    = SelectorEngine.findOne('.swiper-button-prev', element);

	return {
		navigation: {
			nextEl: btnNext || null,
			prevEl: btnPrev || null
		},
		pagination: {
			el: pagination || null
		}
	};
};

/**
 * @param {Object} api
 * @param {Object} o
 * @returns {Object}
 */
const rebuildSlider = (api, o = {}) => {
	const curElement = api.el;
	const curParams  = api.originalParams;
	// const curIndex   = api.realIndex || 0;
	const newParams = extend({}, curParams, {
		init: true// ,
		// on  : {
		// 	afterInit: function() {
		// 		this.slideTo(curIndex);
		// 	}
		// }
	}, o);

	return new Swiper(curElement, newParams);
};

/**
 * @param {HTMLElement} element
 * @param {Object} o
 */
const render = (element, o = {}) => {
	const elSwiper = SelectorEngine.findOne('.swiper', element);

	let api = null;

	if(elSwiper) {
		// Aktuelle Slider-Instanz.
		api = new Swiper(elSwiper, o);

		// Überwachung per Resize-Observer triggert Slider-Update.
		// const resizeObserver = new ResizeObserver(() => {
		// 	api.update();
		// });

		// resizeObserver.observe(element);
	}

	// API-Instanz in aktuellem Element speichern.
	Data.set(element, `${DATA_KEY}${API_KEY}`, api);

	// Initialisierungsstatus setzen.
	if (o.init) {
		Data.set(element, `${DATA_KEY}.initialized`, true);
	}

	return element;
};

// -------
// Public
// -------

/**
 * @param {HTMLElement} element
 * @param {Object} [o={}] - Optionen des Sliders.
 * @constructor
 */
const renderSingle = (element, o = {}) => {
	if (!Data.get(element, `${DATA_KEY}.initialized`)) {
		const sliderControls = detectSliderControls(element);

		let _o = extend({}, DEFAULTS, sliderControls, o);

		_o.lazy.loadPrevNextAmount = o.slidesPerView;

		// Eine Initialisierung gibt es nur einmal.
		EventHandler.one(element, EVENT_INIT, function() {
			render(element, _o);
		});

		// Erscheinen im Viewport.
		EventHandler.on(element, EVENT_ENTER_VIEWPORT, function() {
			const api = Data.get(element, `${DATA_KEY}${API_KEY}`);

			if (api && api.originalParams.autoplay.enabled) {
				api.autoplay.start();
			}
		});

		// Verlassen des Viewport.
		EventHandler.on(element, EVENT_LEAVE_VIEWPORT, function() {
			const api = Data.get(element, `${DATA_KEY}${API_KEY}`);

			if (api && api.autoplay && api.autoplay.running) {
				api.autoplay.stop();
			}
		});

		// ´Starten´ der Komponente wird per Viewport-Observer gesteuert!
		SLIDER_VIEWPORT_OBSERVER.observe(element);
	}

	return element;
};

/**
 * @param {Object} [o={}] - Optionen des Sliders.
 * @constructor
 */
const renderDefault = (o = {}) => {
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	// Events an Elemente anbinden.
	for (const element of collection) {
		const inst = renderSingle(element, o);
	}
};

// Export
export default {
	renderDefault: ((typeof Swiper === 'undefined') ? noop : renderDefault),
	renderSingle : ((typeof Swiper === 'undefined') ? noop : renderSingle),
	rebuild      : ((typeof Swiper === 'undefined') ? noop : rebuildSlider)
};
