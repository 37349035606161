/**
 * Komponente ´Scroll image´
 */

/* eslint-env es6 */

import {
	debounce,
	needJquery
} from '../../../shared/js/utils/index';

// -------
// Private
// -------

const $ = needJquery();

const changeSize = () => {
	$('.scroll-wrapper').each(function() {
		const obj         = $(this);
		const $containerW = $('body').width();
		const $type       = obj.find('.scroll-animation').data('type');

		if ($type !== 'updown') {
			fitContainer(obj, $containerW, $type);
		} else {
			upDownResizer(obj, $containerW, $type);
		}
	});
};

const upDownResizer = (obj, $containerW, $type) => {
	obj.find('.image-bi').css('position', 'relative');
	obj.find('.image-bi').css('left', '0');
	obj.find('.image-bi').css('height', '150%');
	obj.find('.image-bi').css('width', '100%');
	obj.find('.image-bi').css('margin-left', 'auto');
	obj.find('.image-bi').css('margin-right', 'auto');

	const $colW           = obj.find('.content-col').width();
	const $position       = obj.find('.image-bi').offset().top;
	const $positionOffset = parseInt($position, 10) - 400;

	if ($positionOffset < $(document).scrollTop()) {
		if (($(document).scrollTop()) > $positionOffset && ($(document).scrollTop()) < $position) {
			const $tempOff      = $(document).scrollTop() - $positionOffset;
			const $tempAbs      = 400;
			const $percent      = 100 - ($tempOff * 100) / $tempAbs;
			const $percentImage = 100 + ($percent * 2);

			obj.find('.image-bi').css('height', $percentImage + '%');

			const $offset     = $containerW - $colW;
			const $offsetPart = $offset * ((100 - $percent) / 100);
			const $imageSize  = $containerW - $offsetPart;

			obj.find('.image-bi').css('width', $imageSize + 'px');
		}
		if (($(document).scrollTop()) > $position) {
			obj.find('.image-bi').css('height', '100%');
			obj.find('.image-bi').css('width', $colW + 'px');
		}
	} else {
		obj.find('.image-bi').css('height', '300%');
		obj.find('.image-bi').css('width', $containerW + 'px');
	}
};

const fitContainer = (obj, $containerW, $type) => {
	const $position       = obj.find(".image-bi").offset().top;
	const $positionOffset = parseInt($position, 10) - 400;

	if ($containerW > 768 && $type !== 'updown') {
		obj.find('.image-bi').css('position', 'absolute');

		const $imgH = obj.find('.content-col').height();

		obj.find('.image-bi').height($imgH);

		const $colW = obj.find('.col-md-6').width();
		const $colL = obj.find('.image-col').offset().left + 10;

		if ($positionOffset < $(document).scrollTop()) {
			const $size = $containerW - Math.min($containerW, ($(document).scrollTop() - $positionOffset) * 4);

			let $percent = 0;

			if ($size > $containerW) {
				obj.find('.image-bi').width($containerW);
				obj.find('.image-bi').css('left', 0);
			}

			if ($size < $containerW && $size > $colW) {
				const $tempOff = $size - $colW;
				const $tempAbs = $containerW - $colW;

				$percent = 100 - ($tempOff * 100) / $tempAbs;

				const $leftoffset = $colL * ($percent / 100);

				obj.find('.image-bi').width($size);
				obj.find('.image-bi').css('left', $leftoffset);
			}

			if ($size < $colW) {
				obj.find('.image-bi').width($colW);
				obj.find('.image-bi').css('left', $colL);
			}
		} else {
			obj.find('.image-bi').width($containerW);
			obj.find('.image-bi').css('left', 0);
		}

	} else {
		obj.find('.image-bi').css('position', 'relative');
		obj.find('.image-bi').css('left', '0');
		obj.find('.image-bi').css('height', '200%');
		obj.find('.image-bi').css('width', '100%');

		if ($positionOffset < $(document).scrollTop()) {
			if (($(document).scrollTop()) > $positionOffset && ($(document).scrollTop()) < $position) {
				const $tempOff      = $(document).scrollTop() - $positionOffset;
				const $tempAbs      = 400;
				const $percent      = 100 - ($tempOff * 100) / $tempAbs;
				const $percentImage = 100 + ($percent * 2);

				obj.find('.image-bi').css('height', $percentImage + '%');
			}

			if (($(document).scrollTop()) > $position) {
				obj.find('.image-bi').css('height', '100%');
			}
		} else {
			obj.find('.image-bi').css('height', '300%');
		}
	}
};

const Scrollimage = () => {
	if ($) {
		// Scroll-Event
		window.addEventListener('scroll', () => {
			changeSize();
		});

		// Resize-Event
		window.addEventListener('resize', debounce(() => {
			changeSize();
		}, 200));

		changeSize();
	}
};

// Export
export default Scrollimage;
