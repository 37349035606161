import SelectorEngine from '../../shared/js/dom/selector-engine';
import Manipulator    from '../../shared/js/dom/manipulator';

//
// Public
//

const header = () => {
	/* eslint-disable no-console */
	// if (processEnv === 'development') {
	// 	console.log('Header');
	// }
	/* eslint-enable no-console */
	let skipToContent = SelectorEngine.findOne('#page-header .skip-to-content');

	if (document.body.classList.contains('page-error') && skipToContent) {
		skipToContent.remove();

		skipToContent = null;
	}

	if (!skipToContent) {
		return;
	}

	//
	// Skip to content
	//

	let tlSkipToContent = gsap.timeline(); //create the timeline

	tlSkipToContent
		.set(skipToContent, {
			opacity: 0,
			y      : -50
		})
		.to(skipToContent, {
			duration: 0.3,
			opacity : 1,
			y       : 0
		})
		.to(skipToContent, {
			duration: 0.3,
			ease    : "sine.inOut",
			repeat  : -1,
			y       : -10,
			yoyo    : true
		});
};

export default header;
