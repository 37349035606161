import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Data           from '../../../shared/js/dom/data';
import EventHandler   from '../../../shared/js/dom/event-handler';
import Manipulator    from '../../../shared/js/dom/manipulator';

// -------
// Private
// -------

// const $ = needJquery();
//
const NAME      = 'sketchfabmodel';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const EVENT_INIT           = `init${EVENT_KEY}`;
const EVENT_ENTER_VIEWPORT = `enter-viewport${EVENT_KEY}`;
const EVENT_LEAVE_VIEWPORT = `leave-viewport${EVENT_KEY}`;

/**
 * Slider nur initialisieren wenn er im Viewport auftaucht.
 *
 * @type {IntersectionObserver}
 */
const SKETCHFABMODEL_VIEWPORT_OBSERVER = new IntersectionObserver(function(entries, observer) {
	for (const element of entries) {
		const target = element.target;

		if (element.isIntersecting) {
			if(!Data.get(target,`${DATA_KEY}.initialized`)) {
				EventHandler.trigger(target, EVENT_INIT);
			} else {
				EventHandler.trigger(target, EVENT_ENTER_VIEWPORT);
			}
		} else {
			EventHandler.trigger(target, EVENT_LEAVE_VIEWPORT);
		}
	}
});

/**
 * @param {HTMLElement} element
 * @param {Object} o
 */
const render = (element, o = {}) => {
	const iframe       = SelectorEngine.findOne('iframe', element);
	const url = Manipulator.getDataAttribute(iframe, 'src');

	if (url) {
		iframe.setAttribute('src', url);
	}

	// Initialisierungsstatus setzen.
	Data.set(element,`${DATA_KEY}.initialized`, true);
	Manipulator.setDataAttribute(element, 'initialized', 'true');
};

// -------
// Public
// -------

/**
 * Alle vorhandenen ´Mediengalerien´ initialisieren.
 *
 * @constructor
 */
const Sketchfabmodel = () => {
	// Kollektion von Elementen.
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	// Events an Elemente anbinden.
	for (const element of collection) {
		// Eine Initialisierung gibt es nur einmal.
		EventHandler.one(element, EVENT_INIT, function(event) {
			render(event.target);
		});

		// ´Starten´ der Kompoente wird per Viewport-Observer gesteuert!
		SKETCHFABMODEL_VIEWPORT_OBSERVER.observe(element);
	}
};

// Export
export default Sketchfabmodel;

