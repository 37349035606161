import {debounce} from '../../../shared/js/utils/index';

import SelectorEngine from '../../../shared/js/dom/selector-engine';

/**
 *
 * @returns {string} - `${number}px`
 */
const getStartWidth = () => {
	const dim = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	return `${dim}px`;
};

const getEndWidth = (element) => {
	const dim = element.getBoundingClientRect();

	return `${dim.width}px`;
};

/**
 * GSAP-Funktionalitäten an einen Player-Container binden.
 *
 * @param {HTMLElement} element - Player-Container (`.player-video`)
 */
const render = (element) => {
	// Manipuliert wird der Mediencontainer ´.player-media´ innerhalb des
	// Players.
	const player = SelectorEngine.findOne('.player', element);
	const vMedia = SelectorEngine.findOne('.video-media', element);
	// const vBody = SelectorEngine.findOne('.video-body', element);

	// Aktuelle Breite für das Poster.
	// Basis ist die Breite des Dokumentes da das Poster "fullwidth" startet.
	let startWidth = getStartWidth();

	// Aktuelle Zielbreite für das Poster.
	// Basis ist die Breite des eigentliche Player-Containers.
	let endWidth = getEndWidth(player);

	// GSAP Timeline.
	const timeline = gsap.timeline(
		{
			scrollTrigger: {
				trigger            : element,
				start              : "top 55%",
				// end                : "+=350px",
				end : () => {
					const wH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
					return "+=" + (wH / 100 * 55);
				},
				immediateRender    : true,
				invalidateOnRefresh: true,
				// markers            : true,
				// pin                : true,
				// pinSpacing         : true,
				// pinReparent        : true,
				// pinType            : 'transform',
				scrub              : true

			}
		}
	);

	// Media
	timeline.fromTo(
		// zu manipulierendes Element.
		vMedia,
		// Angaben "von Eigenschaften"
		{
			'--dnm-video-ply-width-overwrite': () => {
				return startWidth;
			},
			ease                  : "none",
			transformOrigin       : "top center"
		},
		// Angaben "zu Eigenschaften"
		{
			'--dnm-video-ply-width-overwrite': () => {
				return endWidth;
			},
			transformOrigin       : "top center"
		}
	);

	// Resize-Event
	window.addEventListener('resize', debounce(() => {
		// Startbreite neu auslesen.
		startWidth = getStartWidth();

		// Zielbreite neu auslesen.
		endWidth   = getEndWidth(player);

		// ScrollTrigger aktualisieren.
		timeline.scrollTrigger.refresh(true);
	}, 200));
};

/**
 * Alle vorhandenen ´VideoPlayer´ initialisieren.
 */
const video = () => {
	// GSAP-Plugin registrieren.
	gsap.registerPlugin(ScrollTrigger);

	// Feld aller Player.
	const collection = SelectorEngine.find('.video'); //.player-video

	// Player einzeln initialisieren.
	for (const element of collection) {
		render(element);

		/*const child = SelectorEngine.findOne('.player-media', element);

		const tl    = gsap.timeline({
			scrollTrigger: {
				trigger: container,
				scrub  : true,
				markers: true,
				 invalidateOnResize: true,
				// pin    : true,
				// pinSpacing: false,
				start  : "top 50%",
				end    : "+=50%"
			}
		})
		.fromTo(child, {
			'--dnm-width-animated': function() {
				return getStartWidth();
			},
			ease                  : "none",
			transformOrigin       : "center top"
		}, {
			'--dnm-width-animated': function() {
				return getEndWidth(container);
			}()
		});*/
	}
};

// Export
export default video;
