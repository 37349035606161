import {getRootVar} from '../shared/js/utils/index';

// import SelectorEngine from '../shared/js/dom/selector-engine';
// import Manipulator    from '../shared/js/dom/manipulator';

import components from './components/components';
import initial    from './initial/initial';
import header     from './layout/header';
import navigation from './layout/navigation';
import vendors    from './vendors/vendors';

window.Exhibition = window.Exhibition || {};
window.Exhibition.prefix = getRootVar('global-rootvar-prefix');

document.addEventListener("DOMContentLoaded", function(event) {
	// Komponenten
	components();

	// Basisfunktionalitäten etc.
	initial();

	// Seitenkopf
	header();

	// Seitenkopf
	navigation();

	// Vendors
	vendors();
});

// export default function main() {
// 	document.addEventListener("DOMContentLoaded", function(event) {
// 		if (processEnv === 'development') {
// 			console.log('DEVELOPMENT'); // eslint-disable-line no-console
// 		}
//
// 		components();
// 		core();
// 	});
// }
