import {
	getTransitionDuration,
	normalizeAttributeValue,
	onDOMContentLoaded
} from '../../../shared/js/utils';
import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/js/utils/scroll';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Manipulator    from '../../../shared/js/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'chapter-intro';
const DATA_KEY  = `ifab.${NAME}`;
// const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

let chapterIntro;
let chapterIntroId;

const bindAnimation = () => {
	const firstImage  = SelectorEngine.findOne('.chapter-intro-media', chapterIntro);
	const secondImage = SelectorEngine.findOne('.chapter-intro-overlay', chapterIntro);

	// Dauer der Animation vom Element holen.
	const duration = getTransitionDuration(firstImage);

	// GSAP-Timeline (Events)
	const tl = gsap.timeline({
		onStart   : function () {
			Manipulator.addClass(chapterIntro, '-started');
		},
		onComplete: function () {
			window.setTimeout(() => {
				Manipulator.removeClass(chapterIntro, '-started');
				Manipulator.addClass(chapterIntro, '-playing');

				// Das 2'te Bild aus dem DOM entfernen.
				secondImage.remove();
			}, (duration / 2));

			window.setTimeout(() => {
				Manipulator.removeClass(chapterIntro, '-playing');
				Manipulator.addClass(chapterIntro, '-finished');

				// Scrollen des Body entsperren.
				unlockBodyScrolling(chapterIntro);

				// Intro als "gesehen" speichern.
				sessionStorage.setItem(`${window.Exhibition.prefix}${chapterIntroId}`, 'true');
			}, duration);
		}
	});

	tl.to(
		secondImage,
		{
			duration: (duration / 1000),
			ease    : 'ease-in-out',
			opacity : 0
		},
		// Warte x Sekunden mit dem Start.
		'< 1'
	);

	onDOMContentLoaded(() => {
		tl.play();
	});
};

// -------
// Public
// -------

/**
 * Prüfe auf Vorhandensein des Elementes und initialisiere es ggf..
 *
 * @constructor
 */
const ChapterIntro = () => {
	chapterIntro = SelectorEngine.findOne('[data-chapter-intro]');

	if (!chapterIntro) {
		return;
	}

	chapterIntroId = Manipulator.getDataAttribute(chapterIntro, 'chapter-intro') ?? 'none';

	const wasViewed = sessionStorage.getItem(`${window.Exhibition.prefix}${chapterIntroId}`);

	// Wurde das Intro vom Nutzer schon gesehen.
	if (wasViewed) {
		Manipulator.addClass(chapterIntro, '-canceled');
	} else {
		Manipulator.removeClass(chapterIntro, '-canceled');

		const checkScroll = () => {
			 if (window.scrollY === 0) {
				 window.removeEventListener('scroll', checkScroll, false);

				// Scrollen des Body sperren.
				lockBodyScrolling(chapterIntro);

				 const loadImages = imagesLoaded(chapterIntro);

				 // Nach erfolgtem laden der Bilder die Animation initialisieren.
				 loadImages.on('done', bindAnimation);
			 }
		};

		// Document-Scroll-Event
		window.addEventListener('scroll', checkScroll, false);

		if (window.scrollY === 0) {
			checkScroll();
		} else {
			window.scrollTo({
				top : 0,
				left: 'auto'
			});
		}
	}
};

// Export
export default ChapterIntro;
