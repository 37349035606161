import {
	debounce,
	extend
} from '../../../shared/js/utils/index';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Data           from '../../../shared/js/dom/data';
import EventHandler   from '../../../shared/js/dom/event-handler';

import Slider from '../slider/slider';

// -------
// Private
// -------

// const $ = needJquery();
//
const NAME      = 'mediagallery';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const EVENT_INIT           = `init${EVENT_KEY}`;
const EVENT_ENTER_VIEWPORT = `enter-viewport${EVENT_KEY}`;
const EVENT_LEAVE_VIEWPORT = `leave-viewport${EVENT_KEY}`;

const DEFAULTS = {
	respectPageWidth: true,
	spaceBetween    : 38
};

/**
 * Slider nur initialisieren wenn er im Viewport auftaucht.
 *
 * @type {IntersectionObserver}
 */
const MEDIAGALLERY_VIEWPORT_OBSERVER = new IntersectionObserver(function(entries, observer) {
	for (const element of entries) {
		const target = element.target;

		if (element.isIntersecting) {
			if(!Data.get(target,`${DATA_KEY}.initialized`)) {
				EventHandler.trigger(target, EVENT_INIT);
			} else {
				EventHandler.trigger(target, EVENT_ENTER_VIEWPORT);
			}
		} else {
			EventHandler.trigger(target, EVENT_LEAVE_VIEWPORT);
		}
	}
});

/**
 * X-Abstand eines Elementes zum Dokument bestimmen.
 *
 * @param {HTMLElement} element
 * @returns {number}
 */
const getXOffset = (element) => {
	let res = 0;

	if (element) {
		const wW = document.body.clientWidth;
		const pW = element.getBoundingClientRect().width;

		res = Math.round((wW - pW) / 2);
	}

	return res;
};

/**
 * @param {HTMLElement} element
 * @param {Object} o
 */
const renderSlider = (element, o) => {
	const parent       = SelectorEngine.parents(element, '.content');
	const xOffset      = getXOffset(parent[0]);
	const pluginParams = {
		autoHeight         : false,
		loop               : false,
		mousewheel         : {
			releaseOnEdges: true
		},
		centeredSlides     : false,
		slidesPerView      : 'auto',
		slidesOffsetAfter  : (o.respectPageWidth) ? xOffset : o.spaceBetween,
		slidesOffsetBefore : (o.respectPageWidth) ? xOffset : o.spaceBetween,
		spaceBetween       : o.spaceBetween,
		speed              : 400,
		watchSlidesProgress: false
	};
	const el           = Slider.renderSingle(element, pluginParams);

	window.addEventListener('resize', debounce(() => {
		// X-Abstand des Elterncontainers zum Dkument.
		const xOffset = getXOffset(parent[0]);

		// Aktuelle Slider-Instanz.
		let api = Data.get(el, 'ifab.slider.data-api');

		if(api) {
			api = Slider.rebuild(api, {
				slidesOffsetAfter : (o.respectPageWidth) ? xOffset : o.spaceBetween,
				slidesOffsetBefore: (o.respectPageWidth) ? xOffset : o.spaceBetween
			});

			Data.set(element, 'ifab.slider.data-api', api);
		}
	}, 200));

	// Initialisierungsstatus setzen.
	Data.set(element,`${DATA_KEY}.initialized`, true);
};

// -------
// Public
// -------

/**
 * Alle vorhandenen ´Mediengalerien´ initialisieren.
 *
 * @constructor
 */
const MediaGallery = (o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	// Kollektion von Elementen.
	const galleries = SelectorEngine.find(`[data-${NAME}]`);

	// Events an Elemente anbinden.
	for (const gallery of galleries) {
		// Eine Initialisierung gibt es nur einmal.
		EventHandler.one(gallery, EVENT_INIT, function(event) {
			renderSlider(event.target, _o);
		});

		// ´Starten´ der Kompoente wird per Viewport-Observer gesteuert!
		MEDIAGALLERY_VIEWPORT_OBSERVER.observe(gallery);
	}
};

// Export
export default MediaGallery;

