/**
 * Vendors ´Fancybox´
 */

/* eslint-env es6 */

import {
	needJquery,
	noop
} from '../../../shared/js/utils/index';

import SelectorEngine from '../../../shared/js/dom/selector-engine';

// -------
// Private
// -------

const $ = needJquery();

// ------
// Public
// ------

const setup = () => {
	/* eslint-disable no-console */
	// if (processEnv === 'development') {
	// 	console.log('Vendors > Fancybox');
	// }
	/* eslint-enable no-console */

	Fancybox.defaults.hideScrollbar = false;
	Fancybox.defaults.mainClass     = 'fancybox-custom';
	Fancybox.defaults.Thumbs        = false;
	// Fancybox.defaults.Toolbar       = false;

	Fancybox.defaults.caption = function(fancybox, carousel, slide) {
		const figureCaption = SelectorEngine.next(slide.$trigger, '.figure__caption')[0];

		let caption = slide.caption || '';

		if (figureCaption) {
			caption = figureCaption.innerHTML;
		}

		return caption;
	};

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false
	});
};

// Export
export default ($ ? setup : noop);
