// import {scrollToElement} from '../shared/js/utils/scroll';
import {focusVisible} from '../../shared/js/utils/focus-visible';
import {loadScript}    from '../../shared/js/utils/load-script';
import lazyLoad        from '../../shared/js/utils/lazy-load';

import SelectorEngine from '../../shared/js/dom/selector-engine';
import Manipulator    from '../../shared/js/dom/manipulator';

const observerInView = new IntersectionObserver(
	function(entries, observer) {
		for (const element of entries) {
			const target = element.target;

			if (element.isIntersecting) {
				Manipulator.addClass(target, '-in-view');
			} else {
				Manipulator.removeClass(target, '-in-view');
			}
		}
	}/*, {
		threshold: 0.1
	}*/
);

const initial = () => {
	/* eslint-disable no-console */
	// if (processEnv === 'development') {
	// 	console.log('Initial');
	//
	// 	console.log(getSupport());
	// }
	/* eslint-enable no-console */

	//
	// Accessibility (`:focus` erst bei Tastaturnavigation anzeigen.)
	//

	const collectionFocusVisible = SelectorEngine.find('input, textarea, select, label, a, button');

	for (const element of collectionFocusVisible) {
		focusVisible.observe(element);
	}

	//
	// Lazyload von Bildern initialisieren.
	//
	// Lazyload von Bildern initialisieren.
	lazyLoad(function() {
		const lazyElements = SelectorEngine.find('[loading="lazy"]');

		if (lazyElements) {
			for (const element of lazyElements) {
				Manipulator.addClass(element, 'lazyload');
			}

			window.lazySizesConfig          = window.lazySizesConfig || {};
			window.lazySizesConfig.loadMode = 1;

			loadScript(`${window.PageConf.path.dist}shared/vendors/lazysizes/lazysizes.min.js`, {
				async: true,
				id   : 'lazySizes'
			});
		}
	});

	// Utils
	// scrollToElement('.skip-to-content');

	const checkInView = SelectorEngine.find('[data-check-inview]');

	for (const element of checkInView) {
		observerInView.observe(element);
	}
};

export default initial;
