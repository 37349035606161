import {
	debounce,
	extend
} from '../../../shared/js/utils/index';
import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/js/utils/scroll';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Data           from '../../../shared/js/dom/data';
import EventHandler   from '../../../shared/js/dom/event-handler';
import Manipulator    from '../../../shared/js/dom/manipulator';

// -------
// Private
// -------

// const $ = needJquery();
//
const NAME      = 'imageGroup';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const EVENT_INIT = `init${EVENT_KEY}`;

let markedImageOverlay      = null;
let markedImageOverlayChild = null;
let markedImageActiveMarker = null;
let markedImageActiveDetail = null;

/**
 * Slider nur initialisieren wenn er im Viewport auftaucht.
 *
 * @type {IntersectionObserver}
 */
const MARKED_IMAGE_VIEWPORT_OBSERVER = new IntersectionObserver(function(entries, observer) {
	for (const element of entries) {
		const target = element.target;

		if (
			element.isIntersecting
			&& !Data.get(target,`${DATA_KEY}.initialized`)
		) {
			EventHandler.trigger(target, EVENT_INIT);
		}
	}
});

/**
 * Größe des Overlay kalkulieren
 */
const calcOverlaySize = () => {
	const diameter = (Math.sqrt(Math.pow($(window).height(), 2) + Math.pow($(window).width(), 2)) * 3);

	gsap.to(markedImageOverlayChild, {
		duration: 0,
		height  : diameter + 'px',
		width   : diameter + 'px',
		top     : -(diameter / 2) + 'px',
		left    : -(diameter / 2) + 'px'
	});
};

/**
 * Overlay bereitstellen
 */
const appendOverlay = () => {
	markedImageOverlay = Manipulator.createElementFrom('<div aria-hidden="true" class="marked-image-overlay" id="marked-image-overlay" tabindex="-1"><span></span></div>');

	Manipulator.elementAppend(markedImageOverlay);

	markedImageOverlayChild = SelectorEngine.findOne('span', markedImageOverlay);

	// Neukalkulation der Dimension bei `window.resize`.
	window.addEventListener('resize', debounce(() => {
		window.requestAnimationFrame(calcOverlaySize);
	}, 200));
};

/**
 *
 * @param marker
 * @param detail
 * @param idx
 */
const setupMarker = (marker, detail, idx = 0) => {
	// Blende Marker mit verzögerung ein.
	// marker.style.transitionDelay = `${0.5 + ((idx + 1) * 0.125)}s`;

	Manipulator.addClass(marker, '-show');

	// Klickevent.
	EventHandler.on(marker, `click${EVENT_KEY}`, function(event) {
		event.preventDefault();

		markedImageActiveMarker = marker;

		markedImageOverlay.style.top  = `${event.clientY}px`;
		markedImageOverlay.style.left = `${event.clientX}px`;

		calcOverlaySize();

		EventHandler.trigger(detail, `doShow${EVENT_KEY}`);
	});
};

/**
 *
 * @param detail
 */
const setupDetail = (detail) => {
	const media = SelectorEngine.findOne('.marked-image-detail-media > div', detail);
	const text  = SelectorEngine.findOne('.marked-image-detail-text', detail);

	// Animation timeline
	const tlGsap = gsap
		.timeline({
			paused: true,
		})
		.eventCallback('onReverseComplete', function() {
			Manipulator.setAria(detail, 'hidden', 'true');
			Manipulator.removeClass(detail, '-active');

			Manipulator.removeClass(markedImageActiveMarker, '_active');

			markedImageActiveMarker = null;
			markedImageActiveDetail = null;

			// Scrollen des `<body/>` entsperren.
			unlockBodyScrolling(detail);
		})
		.eventCallback('onStart', function() {
			Manipulator.setAria(detail, 'hidden', 'false');
			Manipulator.addClass(detail, '-active');

			markedImageActiveDetail = detail;

			Manipulator.addClass(markedImageActiveMarker, '_active');

			// Scrollen des `<body/>` sperren
			lockBodyScrolling(detail);
		})
		.to(markedImageOverlayChild, {
			duration: 0.75,
			scale   : 1
		})
		.to(detail, {
			delay   : -0.5,
			duration: 0.5,
			opacity : 1
		})
		.to(media, {
			delay   : -0.25,
			duration: 0.35,
			scale   : 1
		})
		.to(text, {
			delay   : -0.25,
			duration: 0.35,
			opacity : 1,
			top     : 0
		});

	// ...in Element speichern.
	Data.set(detail,`${DATA_KEY}.animation`, tlGsap);

	// Event ´show´.
	EventHandler.on(detail, `doShow${EVENT_KEY}`, () => {
		const anim = Data.get(detail,`${DATA_KEY}.animation`);

		anim.play();
	});

	// Event ´hide´.
	EventHandler.on(detail, `doHide${EVENT_KEY}`, () => {
		const anim = Data.get(detail,`${DATA_KEY}.animation`);

		anim.reverse();
	});

	// Button ´schließen´.
	EventHandler.on(detail,`click${EVENT_KEY}`, '[data-close]', (event) => {
		event.preventDefault();
		event.stopPropagation();

		EventHandler.trigger(event.delegateTarget, `doHide${EVENT_KEY}`);
	});
};

/**
 * @param {HTMLElement} element
 * @param {Object} o
 */
const render = (element, o = {}) => {
	const markers = SelectorEngine.find('[data-marked-image-marker]', element);

	for (const [idx, marker] of markers.entries()) {
		const detailId = Manipulator.getAria(marker, 'controls');
		const detail = SelectorEngine.findOne(`#${detailId}`);

		if (detail) {
			setupMarker(marker, detail, idx);
			setupDetail(detail);
		} else {
			marker.remove();
		}
	}

	// Initialisierungsstatus setzen.
	Data.set(element,`${DATA_KEY}.initialized`, true);
};

// -------
// Public
// -------

/**
 * Alle vorhandenen ´Mediengalerien´ initialisieren.
 *
 * @param {Object} o
 * @constructor
 */
const ImageGroup = (o = {}) => {
	// Kollektion von Elementen.
	const elements = SelectorEngine.find('[data-marked-image]');

	if (elements.length > 0) {
		// Overlay bereitstellen.
		appendOverlay();

		// Events an Elemente anbinden.
		for (const element of elements) {
			// Eine Initialisierung gibt es nur einmal.
			EventHandler.one(element, EVENT_INIT, function(event) {
				render(event.target);
			});

			// ´Starten´ der Komponete wird per Viewport-Observer gesteuert!
			MARKED_IMAGE_VIEWPORT_OBSERVER.observe(element);
		}
	}
};

// Export
export default ImageGroup;
