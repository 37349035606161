import {getTransitionDuration, triggerReflow} from '../../../shared/js/utils/index';

import SelectorEngine        from '../../../shared/js/dom/selector-engine';
import Manipulator           from '../../../shared/js/dom/manipulator';
import EventHandler          from '../../../shared/js/dom/event-handler';
import Data                  from '../../../shared/js/dom/data';

// -------
// Private
// -------

const NAME      = 'textblock';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const EVENT_INIT           = `init${EVENT_KEY}`;
const EVENT_ENTER_VIEWPORT = `enter-viewport${EVENT_KEY}`;
const EVENT_LEAVE_VIEWPORT = `leave-viewport${EVENT_KEY}`;

/**
 * Slider nur initialisieren wenn er im Viewport auftaucht.
 *
 * @type {IntersectionObserver}
 */
const OBSERVER_MEDIAFULLSCREEN = new IntersectionObserver(function(entries, observer) {
	for (const element of entries) {
		const target = element.target;

		if (element.isIntersecting) {
			if (!Data.get(target, `${DATA_KEY}.initialized`)) {
				triggerReflow(target);
				EventHandler.trigger(target, EVENT_INIT);
			}
		} else {
			EventHandler.trigger(target, EVENT_LEAVE_VIEWPORT);
		}
	}
}, {
	threshold: [0.5]
});

const renderMediaFullScreen = (container) => {
	const cImage = Data.get(container, 'image');
	const cText           = SelectorEngine.findOne('.textblock-text', container);
	const cMedia         = Data.get(container, `cMedia`);
	const cMediaImg = SelectorEngine.findOne('.picture', cMedia);
	const mediaOrigin    = SelectorEngine.findOne('.textblock-media', container);

	// Dauer der Animation vom Element holen.
	const gtd      = getTransitionDuration(cText);
	const duration = gtd / 1000; // Sekunden / 100 = Millisekunden

	// Ziel-Dimension etc.
	let containerDim = container.getBoundingClientRect();
	let cMediaDim    = cMedia.getBoundingClientRect();
	let cMediaImgDim = cMediaImg.getBoundingClientRect();

	cImage.style.left = (containerDim.left * -1) + 'px';
	cImage.style.top  = cMedia.offsetTop + 'px';

	//
	// GSAP-Timeline
	//

	const tl = gsap.timeline({
		onComplete: function() {
			cImage.remove();
		}
	});

	// Media full screen
	tl.to(
		cImage,
		{
			duration: duration,
			ease    : 'ease-out',
			height  : () => {
				return cMediaImgDim.height;
			},
			left    : () => {
				return (cMediaDim.left - containerDim.left);
			},
			width   : () => {
				return cMediaImgDim.width;
			}
		}
	);

	// Text
	tl.to(
		cText,
		{
			// duration  : (duration / 2),
			// ease      : 'ease-out',
			// translateX: 0,
			onStart: () => {
				Manipulator.addClass(container, '-finished');
			}
		},
		'-= 0.5'
	);

	tl.play();

	// Resize-Event
	// window.addEventListener('resize', debounce(() => {
	// 	dimElement        = element.getBoundingClientRect();
	// 	dimMediaOrigin    = mediaOrigin.getBoundingClientRect();
	// 	dimMediaOriginImg = mediaOriginImg.getBoundingClientRect();
	// }, 200));

	// Initialisierungsstatus setzen.
	Data.set(container,`${DATA_KEY}.initialized`, true);
};


const setupMediaFullScreen = (container, image) => {
	const cMedia        = SelectorEngine.findOne('.textblock-media', container);
	const cBcr = container.getBoundingClientRect();

	image.style.left = (cBcr.left * -1) + 'px';
	image.style.top  = cMedia.offsetTop + 'px';

	Data.set(container, `image`, image);
	Data.set(container, `cMedia`, cMedia);

	EventHandler.one(container, EVENT_INIT, function(event) {
		renderMediaFullScreen(event.target);
	});

	// ´Starten´ der Kompoente wird per Viewport-Observer gesteuert!
	OBSERVER_MEDIAFULLSCREEN.observe(container);
};

// -------
// Public
// -------

/**
 * Alle vorhandenen ´Textblöcke´ ggf. initialisieren.
 *
 * @constructor
 */
const Textblock = () => {
	// Kollektion von Elementen.
	const collectionFullScreen = SelectorEngine.find(`.textblock[data-${NAME}="media-fullscreen"]`);

	// Events an Elemente anbinden.
	for (const element of collectionFullScreen) {
		setupMediaFullScreen(
			element,
			SelectorEngine.findOne('.media-fullscreen', element)
		);
	}
};

// Export
export default Textblock;
