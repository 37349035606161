import {getOffset}  from './index';
import EventHandler from '../dom/event-handler';

// ------
// Privat
// ------

const bodyScrollingLockLevels = new Set();

// ------
// Public
// ------

/**
 * Body-Scrolling sperren.
 * Speicherung welche Elemente eine Sperre angefordert haben, sodass mehrere
 * Sperrstufen, ohne vorzeitiges Entsperren, möglich sind.
 *
 * @param  {HTMLElement, Element} element - Element was die Sperre anfordert.
 */
const lockBodyScrolling = (element) => {
	bodyScrollingLockLevels.add(element);

	document.body.classList.add('_scroll-lock');
};

/**
 * Body-Scrolling entsperren.
 * Das Scrollen wird erst entsperrt, wenn alle Sperrstuffen, Elemente die eine
 * Sperre angefordert haben, diese Methode aufrufen.
 *
 * @param {HTMLElement, Element} element - Element was die Sperre aufhebt.
 */
const unlockBodyScrolling = (element) => {
	bodyScrollingLockLevels.delete(element);

	if (bodyScrollingLockLevels.size === 0) {
		document.body.classList.remove('_scroll-lock');
	}
};

/**
 * Element in den Viewport seines Container scrollen.
 *
 * @param {HTMLElement, Element} element
 * @param {HTMLElement, Element} container
 * @param {String} [direction=both]
 * @param {String} [behavior=smooth]
 */
const scrollElementIntoView = (element, container, direction = 'both', behavior= 'smooth') => {
	const offset     = getOffset(element, container);
	const offsetTop  = offset.top + container.scrollTop;
	const offsetLeft = offset.left + container.scrollLeft;

	const minX = container.scrollLeft;
	const maxX = container.scrollLeft + container.offsetWidth;
	const minY = container.scrollTop;
	const maxY = container.scrollTop + container.offsetHeight;

	let scrollOptions = {
		top     : 0,
		left    : 0,
		behavior: behavior || 'smooth'
	};

	if (direction === 'horizontal' || direction === 'both') {
		if (offsetLeft < minX) {
			scrollOptions.left = offsetLeft;
		} else if (offsetLeft + element.clientWidth >= maxX) {
			scrollOptions.left = offsetLeft - container.offsetWidth + element.clientWidth;
		}
	}

	if (direction === 'vertical' || direction === 'both') {
		const gcs = getComputedStyle(element);
		const smt = parseInt(gcs.getPropertyValue('scroll-margin-block-start') || gcs.getPropertyValue('scroll-margin-top') || 0, 10);

		scrollOptions.top = offsetTop - smt;

		// if (offsetTop < minY) {
		//
		// } else if (offsetTop + element.clientHeight > maxY) {
		// 	scrollOptions.top = (offsetTop - container.offsetHeight + element.clientHeight);
		// }
	}

	if (container.scrollTo) {
		container.scrollTo(scrollOptions);

		return;
	}

	// Chrome 60 doesn't support `scrollTo`
	container.scrollTop = scrollOptions.top;
};

/*const scrollToElement = (additionalSelectors = '') => {
	let useSelector = '[data-scroll-to-eleemnt]';

	if (additionalSelectors !== '') {
		useSelector += `,${additionalSelectors}`;
	}

	const collection = SelectorEngine.find(useSelector);

	for (const element of collection) {}


	// console.log(collection);

	// 	document.querySelectorAll('a[href^="#"]').forEach(anchor => {
	//     anchor.addEventListener('click', function (event) {
	//         event.preventDefault();
	//         document.querySelector(this.getAttribute('href')).scrollIntoView({
	//             behavior: 'smooth'
	//         });
	//     });
	// });


	// $(document).on('click', 'a[data-scroll]', function (e) {
	//   e.preventDefault();
	//
	//   const full_url = this.href;
	//   if (!full_url) return;
	//   if (full_url.indexOf('#') === -1) return;
	//
	//   const target = full_url.split('#')[1].split('?')[0];
	//   if (!target) return;
	//
	//   const targetElement = $('[name="' + target + '"]');
	//   if (!targetElement.length) return;
	//
	//   const bodyRect = document.body.getBoundingClientRect();
	//   const elemRect = targetElement.get(0).getBoundingClientRect();
	//   const offset = elemRect.top - bodyRect.top;
	//
	//   var offsetModifier = 100; // 100 ist die Höhe des Sticky Headers mit Untermenü
	//   if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
	//     offsetModifier = 80;
	//   }
	//
	//   const scrollPosition = Math.ceil(offset - offsetModifier);
	//   $('html, body').animate({ scrollTop: scrollPosition }, 500);
	//
	//   document.location.hash = target;
	// });
};*/

/**
 *
 * @param additionalSelector

 */

/**
 * Dokument an den Anfang scrollen.
 *
 * @param {String} [additionalSelector=] - zusätzlicher Selector
 * @param {boolean} [explicit=false] - nur den zusätzlichen Selector nutzen
 */
const scrollPageTop = (additionalSelector = '', explicit = false) => {
	let selector = '[data-scroll-page-top]';

	if (additionalSelector) {
		if (explicit) {
			selector = additionalSelector;
		} else {
			selector += ', '+ additionalSelector;
		}
	}

	EventHandler.on(document, 'click', selector, (event) => {
		// preventDefault only for <a> elements (which change the URL) not inside the collapsible element
		if (event.target.tagName === 'A' || (event.delegateTarget && event.delegateTarget.tagName === 'A')) {
			event.preventDefault();
		}

		window.scrollTo({
			top     : 0,
			left    : 0,
			behavior: 'smooth'
		});
	});
};

// Set a variable for our button element.
//const scrollToTopButton = document.getElementById('js-top');

// // Let's set up a function that shows our scroll-to-top button if we scroll beyond the height of the initial window.
// const scrollFunc = () => {
//   // Get the current scroll value
//   let y = window.scrollY;
//
//   // If the scroll value is greater than the window height, let's add a class to the scroll-to-top button to show it!
//   if (y > window.innerHeight) {
//     scrollToTopButton.className = "top-link show";
//   } else {
//     scrollToTopButton.className = "top-link hide";
//   }
// };
//
// window.addEventListener("scroll", scrollFunc);
//
//
//
// // When the button is clicked, run our ScrolltoTop function above!
// scrollToTopButton.onclick = function(e) {
//   e.preventDefault();
//   scrollToTop();
// }

// Export
export {
	lockBodyScrolling,
	unlockBodyScrolling,
	scrollElementIntoView,
	scrollPageTop
};
