import {
	getTransitionDuration,
	normalizeAttributeValue,
	onDOMContentLoaded
} from '../../../shared/js/utils';
import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/js/utils/scroll';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Manipulator from '../../../shared/js/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'intro';
const DATA_KEY  = `ifab.${NAME}`;
// const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

let introContent;
let introId;
let introContentMedia;
let introContentText;
let introImages;

/**
 * Animationen zusammenstellen
 * (Achtung: Reihenfolge ist rückwärts!)
 */
const bindAnimation = () => {
	const duration = 1.75;

	const image1 = SelectorEngine.findOne('.intro-anim-image.-image-1', introImages);
	const image2 = SelectorEngine.findOne('.intro-anim-image.-image-2', introImages);
	const image3 = SelectorEngine.findOne('.intro-anim-image.-image-3', introImages);

	let introContentGbc;
	let introContentMediaGbc;

	//
	// Initiale Eigenschaften der Elemente setzen
	//

	// Bild 3
	image3.style.display   = 'block';
	image3.style.height    = '100%';
	image3.style.left      = 0;
	image3.style.opacity   = 0;
	image3.style.top       = 0;
	image3.style.transform = 'scale(2)';
	image3.style.width     = '100%';

	// Bild 2
	image2.style.display = 'block';
	image2.style.left    = '100%';
	image2.style.top     = 0;
	image2.style.width   = '100%';
	image2.style.height  = '200vh';

	// Bild 1
	image1.style.height = '100%';
	image1.style.left   = '-100%';
	image1.style.top    = 0;
	image1.style.width  = '100%';

	// Introtext
	introContentText.style.opacity = 0;

	//
	// Animation
	//

	const tlImages = gsap.timeline({
		paused    : true,
		onComplete: function () {
			Manipulator.removeClass(introContent, '-playing');
			Manipulator.addClass(introContent, '-finished');

			Manipulator.removeClass(introImages, '-playing');
			Manipulator.addClass(introImages, '-finished');

			// Scrollen des Body entsperren.
			unlockBodyScrolling(introImages);

			// Intro als "gesehen" speichern.
			sessionStorage.setItem(`${window.Exhibition.prefix}${introId}`, 'true');

			// `introImages` entfernen.
			// introImages.remove();
		},
		onStart   : function () {
			introContentGbc      = introContent.getBoundingClientRect();
			introContentMediaGbc = introContentMedia.getBoundingClientRect();

			Manipulator.addClass(introImages, '-playing');

			// Der Introinhalt ist standardmäßig ausgeblendet, damit er bei der Initialisierung nicht sichtbar ist.
			// Er muss nachträglich per Timeout wieder eingeblendet werden, damit es keine ´visuellen´ Blitzer gibt.
			setTimeout(() => {
				Manipulator.addClass(introContent, '-playing');
			}, (duration * 1000));
		}
	});

	// ... Elemente
	tlImages
		// Bild 3: Opacity > scale > move left
		.to(image3, {
			duration: 0.5,
			ease    : 'power2.out',
			// Properties
			opacity: 1
		})
		.to(image3, {
			duration: duration,
			ease    : 'power2.inOut',
			// Properties
			scale: 1
		})
		.to(image3, {
			duration: duration,
			ease    : 'power2.inOut',
			// Properties
			left: '-100%'
		})

		// Bild 2: Move left > scroll up > move right
		.to(image2, {
			delay   : () => (duration * -1),
			duration: duration,
			ease    : 'power2.inOut',
			// Properties
			left: '0'
		})
		.to(image2, {
			duration: 1.5,
			ease    : 'power2.inOut',
			// Properties
			top: '-100vh'
		})
		.to(image2, {
			duration: duration,
			ease    : 'power2.inOut',
			// Properties
			left: '100%'
		})

		// Bild 1: Move right > scale down (finish)
		.to(image1, {
			delay   : () => (duration * -1),
			duration: duration,
			ease    : 'power2.inOut',
			// Properties
			left: '0'
		})
		.to(image1, {
			duration: duration,
			ease    : 'power2.inOut',
			// Properties
			height: () => {
				return introContentMediaGbc.height;
			},
			left  : () => {
				return introContentMediaGbc.x;
			},
			top   : () => {
				return introContentMediaGbc.y;
			},
			width : () => {
				return introContentMediaGbc.width;
			}
		})
		.to(introContentText, {
			delay   : () => (duration * -1),
			duration: duration,
			ease    : 'power2.inOut',
			// Properties
			opacity: 1,
			x      : 0,
			y      : 0
		})
		.to(image1, {
			duration: () => ((duration - 0.5) * -1),
			ease    : 'power2.inOut',
			// Properties
			opacity: 0
		});

	// ... starten
	tlImages.play();
};

// -------
// Public
// -------

/**
 * Prüfe auf Vorhandensein des Elementes und initialisiere es ggf..
 *
 * @constructor
 */
const Intro = () => {
	introContent = SelectorEngine.findOne('[data-intro]');

	if (!introContent) {
		return;
	}

	introId           = Manipulator.getDataAttribute(introContent, 'intro') ?? 'none';
	introContentMedia = SelectorEngine.findOne('.intro-media', introContent);
	introContentText  = SelectorEngine.findOne('.intro-text', introContent);
	introImages       = SelectorEngine.findOne('[data-intro-anim]');

	const wasViewed = sessionStorage.getItem(`${window.Exhibition.prefix}${introId}`);

	// Gibt es Bilder und wurde das Intro noch nicht vom Nutzer gesehen.
	if (introImages && !wasViewed) {
		const checkScroll = () => {
			 if (window.scrollY === 0) {
				 window.removeEventListener('scroll', checkScroll, false);

				// Scrollen des Body sperren.
				lockBodyScrolling(introImages);

				 const loadImages = imagesLoaded(introImages);

				 // Nach erfolgtem laden der Bilder die Animation initialisieren.
				 loadImages.on('done', bindAnimation);
			 }
		};

		// Document-Scroll-Event
		window.addEventListener('scroll', checkScroll, false);

		if (window.scrollY === 0) {
			checkScroll();
		} else {
			window.scrollTo({
				top : 0,
				left: 'auto'
			});
		}
	} else {
		if (introImages) {
			introImages.remove();
		}

		Manipulator.addClass(introContent, '-animation-disabled');
	}
};

// Export
export default Intro;
