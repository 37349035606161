import SharedPlayer from '../../shared/components/player/player';
import SharedTabGroup from '../../shared/components/tab-group/tab-group';

import Intro   from './intro/intro';
import ChapterIntro   from './chapter-intro/chapter-intro';
import Slider         from './slider/slider';
import MediaGallery   from './mediagallery/mediagallery';
import Chapters       from './chapters/chapters';
import Textblock      from './textblock/textblock';
import ImageGroup     from './image-group/image-group';
import Video          from './video/video';
import Sketchfabmodel from './sketchfabmodel/sketchfabmodel';
import Scrollimage    from './scrollimage/scrollimage';

const components = () => {
	Intro();
	ChapterIntro();

	// Mediaplayer
	SharedPlayer.audio();
	SharedPlayer.video();
	SharedTabGroup.all({
		showIndicator: false
	});
	Slider.renderDefault();
	MediaGallery({
		respectPageWidth : true
	});
	Textblock();
	ImageGroup();
	Video();
	Sketchfabmodel();
	Chapters({
		respectPageWidth : true
	});
	Scrollimage();
};

export default components;
