/**
 * Komponente ´Player´
 */

import {
	needJquery,
	noop
} from '../../js/utils';

import SelectorEngine from '../../js/dom/selector-engine';
import Manipulator    from '../../js/dom/manipulator';
import Data           from '../../js/dom/data';

// -------
// Private
// -------

const PLAYER_OBSEREVR = new IntersectionObserver(function(entries, observer) {
	for (const element of entries) {
		var $target = $(element.target);

		if (element.isIntersecting) {
			// if($el.lastPlayed) {
			// 	$(el.target).trigger(EVENT.PLAY + '.player')
			// }
		} else {
			// Player anhalten.
			$target.trigger('stop.player');
		}
	}
}, {
	root     : null,
	threshold: [0.25]
});

const $ = needJquery();

const renderAudio = (element) => {
	const $c     = $(element);
	const $audio = $c.find('audio');
	const uid    = $c[0].id || 'player-' + Math.floor(Math.random() * 1000000);

	$c.playerInstance = new Plyr($audio, {
		autoplay  : false,
		resetOnEnd: true,
		controls  : ['play', 'mute', 'progress', 'current-time', ]
	});

	// Fixes ´Uncaught (in promise) DOMException: play() failed because the
	// user didn't interact with the document first.´
	// If the video has autoplay and is in the viewport, then the error
	// occurs.
	$c.playerInstance.muted = false;

	// Player instance event ´play´ (Plyr).
	$c.playerInstance.on('play', function() {
		if (
			window.activePlayer &&
			window.activePlayer.data('uid') !== $c.data('uid')
		) {
			window.activePlayer.trigger('stop.player');
		}

		$c
			.data({
				'isPlaying': true,
				'isPaused' : false
			})
			.removeClass('-stopped')
			.addClass('-playing');

		window.activePlayer = $c;
	});

	// Player instance event ´pause´ (Plyr).
	$c.playerInstance.on('pause', function() {
		$c
			.data({
				'isPlaying': false,
				'isPaused' : true
			})
			.removeClass('-playing')
			.addClass('-stopped');
	});

	// Container event ´play´
	$c
		.on('play.player', function(ev) {
			if ($c.data('isInitialized')) {
				$c.playerInstance.play();
			}
		})
		// Container event ´stop´
		.on('stop.player', function(ev) {
			if ($c.data('isInitialized')) {
				$c.playerInstance.pause();
			}
		});

	$c
		.data({
			'uid'          : uid,
			'isInitialized': true
		})
		.addClass('-initialized');

	// Player überwachen (Viewport).
	PLAYER_OBSEREVR.observe($c[0]);

	return element;
};

const playerAudio = (element = null) => {
	let group;

	if (element) {
		group = renderAudio(element);
	} else {
		group = [];

		const collection = SelectorEngine.find('[data-player="audio"]');

		for (const element of collection) {
			group.push(renderAudio(element));
		}
	}

	return group;
};

const renderVideo = (element) => {
	const $c      = $(element);
	const $ply    = $c.find('[data-player-media]');
	const $iframe = $c.find('iframe');
	const uid     = $c[0].id || 'player-' + Math.floor(Math.random() * 1000000);

	$c
		.data({
			'uid'          : uid,
			'isInitialized': false,
			'isProtected'  : (typeof $c.data('privacy') !== 'undefined')
		})
		.on('disabled.privacyMode', function() {
			$c
				.removeAttr('data-privacy')
				.data('isProtected', false)
				.off('disable.privacyMode');
		})
		.on('init.player', function() {
			if (!$c.data('isProtected')) {
				$c.playerInstance = new Plyr($ply, {
					autoplay  : true,
					controls  : ['play', 'progress', 'current-time', 'fullscreen'],
					fullscreen: {
						iosNative: true
					},
					// loop      : {
					// 	active: (typeof $container.data('loop') !== 'undefined')
					// },
					playsinline: true,
					resetOnEnd : true,
					settings   : [],
					vimeo      : {
						playsinline: true
					},
					youtube    : {
						autoplay      : 1,
						noCookie      : true,
						rel           : 0,
						showinfo      : 0,
						iv_load_policy: 3,
						modestbranding: 1
					}
				});

				// Fixes ´Uncaught (in promise) DOMException: play() failed because the
				// user didn't interact with the document first.´
				// If the video has autoplay and is in the viewport, then the error
				// occurs.
				$c.playerInstance.muted = false;

				// Player instance event ´play´ (Plyr).
				$c.playerInstance.on('play', function() {
					// Gibt es einen "anderen" aktiven Player, dann stoppe
					// ihn.
					if (
						window.activePlayer &&
						window.activePlayer.data('uid') !== $c.data('uid')
					) {
						window.activePlayer.trigger(EVENT.STOP + '.player');
					}

					// Aktuellen Status auf dem Container setzen.
					$c
						.data({
							'isPlaying': true,
							'isPaused' : false
						})
						.removeClass('-stopped')
						.addClass('-playing');

					// Aktiven Player setzen.
					window.activePlayer = $c;
				});

				// Player instance event ´pause´ (Plyr).
				$c.playerInstance.on('pause', function() {
					// Aktuellen Status auf dem Container setzen.
					$c
						.data({
							'isPlaying': false,
							'isPaused' : true
						})
						.removeClass('-playing')
						.addClass('-stopped');
				});

				// Player instance event ´ready´ (Plyr).
				// $c.playerInstance.on('ready', function() {});

				// Initialisierungsstatus auf dem Container setzen.
				$c
					.data('isInitialized', true)
					.addClass('-initialized');
				// .off(EVENT.INIT + '.player');

				// Player starten.
				$c.playerInstance.play();
			}
		})
		// Container event ´play´
		.on('play.player', function(ev) {
			if ($c.playerInstance) {
				$c.playerInstance.play();
			}
		})
		// Container event ´stop´
		.on('stop.player', function() {
			if ($c.playerInstance) {
				$c.playerInstance.pause();
			}
		});

	// Custom-Playbutton.
	// Die Initialisierung eines Players wird nur auf Anforderung des
	// Nutzers vorgenommen.
	$c.find('[data-player-control="play"]').on('click.play', function(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		if ($c.data('isInitialized')) {
			// Player wurde schon initialisiert > play.
			$c.trigger('play.player');
		} else {
			// Liegt ein ifarem vor, dann muss das Attribut `src` mit dem
			// Wert von `data-src` gesetzt werden.
			if ($iframe.length) {
				$iframe[0].src = $iframe[0].dataset.src;
			}

			// Player initialisieren > play().
			$c.trigger('init.player');
		}
	});

	// Player überwachen (Viewport).
	PLAYER_OBSEREVR.observe($c[0]);

	return element;
};

const playerVideo = (element = null) => {
	let group;

	if (element) {
		group = renderVideo(element);
	} else {
		group = [];

		const collection = SelectorEngine.find('[data-player="video"]');

		for (const element of collection) {
			group.push(renderVideo(element));
		}
	}

	return group;
};

const playerAll = () => {
	playerAudio();
	playerVideo();
};

// Export
export default {
	all  : (($ && typeof Plyr !== 'undefined') ? playerAll : noop),
	audio: (($ && typeof Plyr !== 'undefined') ? playerAudio : noop),
	video: (($ && typeof Plyr !== 'undefined') ? playerVideo : noop)
};
